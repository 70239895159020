.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
iframe+div {
  background: #f5f5f5;
}
.transition-wrapper .transition-item {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 11;
}
.detail-page {
  transform: translate3d(0, 0, 0);
  transition: transform 0.6s, opacity 0.2s;
}

.detail-page.transition-appear {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.detail-page.transition-appear.transition-appear-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.detail-page.transition-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.detail-page.transition-leave.transition-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.list-page {

  transition: transform 0.6s, opacity 0.2s;
  transform: translate3d(0, 0, 0);
}

.list-page.transition-appear {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.list-page.transition-appear.transition-appear-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.list-page.transition-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.list-page.transition-leave.transition-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

@font-face {
  font-family: 'Circular Std Black';
  src: url('./fonts/CircularStd-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std Bold';
  src: url('./fonts/CircularStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std Book';
  src: url('./fonts/CircularStd-Book.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('./fonts/CircularStd-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Harry';
  src: url('./fonts/harry/Harry-Thin.woff2') format('woff2'),
  url('./fonts/harry/Harry-Thin.woff') format('woff');
  font-display: swap;
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Harry';
  src: url('./fonts/harry/Harry-Plain.woff2') format('woff2'),
  url('./fonts/harry/Harry-Plain.woff') format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Harry';
  src: local('Harry Heavy'), local('Harry-Heavy'),
  url('./fonts/harry/Harry-Heavy.woff2') format('woff2'),
  url('./fonts/harry/Harry-Heavy.woff') format('woff');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Harry';
  src: local('Harry Fat'), local('Harry-Fat'),
  url('./fonts/harry/Harry-Fat.woff2') format('woff2'),
  url('./fonts/harry/Harry-Fat.woff') format('woff');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Harry';
  src: local('Harry-Obese'), local('Harry-Obese'),
  url('./fonts/harry/Harry-Obese.woff2') format('woff2'),
  url('./fonts/harry/Harry-Obese.woff') format('woff');
  font-display: swap;
  font-weight: 900;
  font-style: normal;
}

