html {
    scroll-behavior: smooth;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: Circular Std Book;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: #333333;
    letter-spacing: normal;
    height: 100%;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

body::-webkit-scrollbar {
    display: none;
}

input {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 19px;
}

* {
    box-sizing: border-box;
}

#root {
    margin: 0 auto;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: normal;
}

a {
    text-decoration: none;
    color: #333333;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
iframe+div {
  background: #f5f5f5;
}
.transition-wrapper .transition-item {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 11;
}
.detail-page {
  transform: translate3d(0, 0, 0);
  transition: transform 0.6s, opacity 0.2s;
}

.detail-page.transition-appear {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.detail-page.transition-appear.transition-appear-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.detail-page.transition-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.detail-page.transition-leave.transition-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.list-page {

  transition: transform 0.6s, opacity 0.2s;
  transform: translate3d(0, 0, 0);
}

.list-page.transition-appear {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.list-page.transition-appear.transition-appear-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.list-page.transition-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.list-page.transition-leave.transition-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

@font-face {
  font-family: 'Circular Std Black';
  src: url(/static/media/CircularStd-Black.7af3a490.woff) format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std Bold';
  src: url(/static/media/CircularStd-Bold.95bc1341.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std Book';
  src: url(/static/media/CircularStd-Book.4dfce695.woff) format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url(/static/media/CircularStd-Medium.3f7c2da5.woff) format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Harry';
  src: url(/static/media/Harry-Thin.243ace09.woff2) format('woff2'),
  url(/static/media/Harry-Thin.64e90247.woff) format('woff');
  font-display: swap;
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Harry';
  src: url(/static/media/Harry-Plain.7f1deddc.woff2) format('woff2'),
  url(/static/media/Harry-Plain.31b7cfce.woff) format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Harry';
  src: local('Harry Heavy'), local('Harry-Heavy'),
  url(/static/media/Harry-Heavy.d8eba104.woff2) format('woff2'),
  url(/static/media/Harry-Heavy.537f1b99.woff) format('woff');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Harry';
  src: local('Harry Fat'), local('Harry-Fat'),
  url(/static/media/Harry-Fat.2ac2fe89.woff2) format('woff2'),
  url(/static/media/Harry-Fat.457a3226.woff) format('woff');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Harry';
  src: local('Harry-Obese'), local('Harry-Obese'),
  url(/static/media/Harry-Obese.1166729e.woff2) format('woff2'),
  url(/static/media/Harry-Obese.d041ec8b.woff) format('woff');
  font-display: swap;
  font-weight: 900;
  font-style: normal;
}


.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  /* // padding: 24px 12px 64px; */
  max-width: 720px;
  min-height: 332px;
  overflow: hidden;
}

.item {
  display: flex;
  padding: 5px;
  position: relative;
  max-width: 33%;
  height: 30%;
  flex-basis: 100%;
  border-radius: 2px;
  flex-direction: column;
  z-index: 99;
}
.profileicon {
  position: absolute;
  top: 6px;
  left: 0px;
  width: 50px !important;
}
.profileicon img {
  width: 100% !important;
  height: 100% !important;
}
.profilerejected {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #616f80;
  bottom: 4px;
  width: 91%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #fff;
  font-size: 12px;
}

.profilerejected p {
  color: #fff;
  line-height: 20px;
}
.profilerejected img {
  width: 10px !important;
  height: 10px !important;
  margin-left: 10px;
}

.inner-item {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.inner-item img :visited,
:active,
:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.SortableList {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;
  width: 20px;
  height: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}
.SortableItem {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}
.item img {
  object-fit: cover;
  max-width: 100%;
  border-radius: 10px;
  max-height: 100%;
  height: 110px;
  width: 110px;
}
.SortableHelper {
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
  /* width:20px !important; */
}

