html {
    scroll-behavior: smooth;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: Circular Std Book;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: #333333;
    letter-spacing: normal;
    height: 100%;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

body::-webkit-scrollbar {
    display: none;
}

input {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 19px;
}

* {
    box-sizing: border-box;
}

#root {
    margin: 0 auto;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: normal;
}

a {
    text-decoration: none;
    color: #333333;
}
