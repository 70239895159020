.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  /* // padding: 24px 12px 64px; */
  max-width: 720px;
  min-height: 332px;
  overflow: hidden;
}

.item {
  display: flex;
  padding: 5px;
  position: relative;
  max-width: 33%;
  height: 30%;
  flex-basis: 100%;
  border-radius: 2px;
  flex-direction: column;
  z-index: 99;
}
.profileicon {
  position: absolute;
  top: 6px;
  left: 0px;
  width: 50px !important;
}
.profileicon img {
  width: 100% !important;
  height: 100% !important;
}
.profilerejected {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #616f80;
  bottom: 4px;
  width: 91%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #fff;
  font-size: 12px;
}

.profilerejected p {
  color: #fff;
  line-height: 20px;
}
.profilerejected img {
  width: 10px !important;
  height: 10px !important;
  margin-left: 10px;
}

.inner-item {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.inner-item img :visited,
:active,
:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.SortableList {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;
  width: 20px;
  height: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}
.SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}
.item img {
  object-fit: cover;
  max-width: 100%;
  border-radius: 10px;
  max-height: 100%;
  height: 110px;
  width: 110px;
}
.SortableHelper {
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
  /* width:20px !important; */
}
